
































import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  components: { MUsersInput: () => import('@/components/molecules/m-users-input.vue') },

  setup(_, { root }) {
    const form = ref<any>(null)
    const user = computed(() => root.$store.getters['user/getData'])
    const state = reactive({
      valid: true,
      isLoading: false
    })

    const model = reactive({
      users: [],
      message: ''
    })

    const rules = {
      users: [(v: string[]) => !!v || 'Wybierz co najmniej jedną osobę'],
      message: [(v: string) => !!v || 'Podaj treść pierwszej wiadomości']
    }

    const onSubmit = async () => {
      await form.value.validate()
      if (state.valid) {
        const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance
        const data = {
          users: model.users.map((user: any) => user.id),
          message: model.message
        }

        state.isLoading = true

        axiosInstance.post('chat', data)
          .then(({ data: { chat } }) => root.$router.push({ name: 'panel.chat.view', params: { id: chat.id } }))
          .catch(error => console.log(error))
          .finally(() => state.isLoading = false)
      }
    }

    return { form, user, state, model, rules, onSubmit }
  }
})
